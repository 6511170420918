import { AbstractControl, ValidatorFn } from '@angular/forms';
import { FormError } from '@ieCore/enums/form-error';
import { removeFieldError } from '@ieCore/helpers/form.helper';
import { PASSWORD_REGEXP } from 'shared-general-libs/constant';
import { FormResponseDto } from 'shared-general-libs/dto/form';
import {
  FormDateCompareEnum,
  FormFieldType,
  FormNumberCompareEnum,
} from 'shared-general-libs/enum/form';
import { isEmail } from 'validator';

function passwordMatchValidator(
  password: string,
  confirmPassword: string,
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const passwordControl = control.get(password);
    const confirmPasswordControl = control.get(confirmPassword);
    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    if (
      passwordControl.value !== confirmPasswordControl.value &&
      (confirmPasswordControl.touched || confirmPasswordControl.dirty)
    ) {
      confirmPasswordControl.setErrors({ [FormError.PASSWORD_MISMATCH]: true });
    } else {
      removeFieldError(confirmPasswordControl, FormError.PASSWORD_MISMATCH);
    }

    return null;
  };
}

function compareDateValidator(
  opt: FormResponseDto,
  compareOpt: FormResponseDto,
  compareKey?: string,
  type?: string,
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const validateControl = control.get(opt.key);
    const compareControl = control.get(compareKey!);

    if (!validateControl || !compareControl) {
      return null;
    }

    switch (type) {
      case FormDateCompareEnum.AFTER:
        if (new Date(validateControl.value) < new Date(compareControl.value)) {
          validateControl.setErrors({
            [FormError.DATE_MUST_BE_GREATER_THAN]: {
              invalid: true,
              field: opt.label,
              compareField: compareOpt.label,
            },
          });
        } else {
          removeFieldError(
            validateControl,
            FormError.DATE_MUST_BE_GREATER_THAN,
          );
        }
        break;
      case FormDateCompareEnum.BEFORE:
        if (new Date(validateControl.value) > new Date(compareControl.value)) {
          validateControl.setErrors({
            [FormError.DATE_MUST_BE_LESS_THAN]: {
              invalid: true,
              field: opt.label,
              compareField: compareOpt.label,
            },
          });
        } else {
          removeFieldError(validateControl, FormError.DATE_MUST_BE_LESS_THAN);
        }
        break;
      case FormDateCompareEnum.AFTER_OR_EQUAL:
        if (
          new Date(validateControl.value) <=
          getDateValueForCompare(compareControl.value, compareOpt.type)!
        ) {
          validateControl.setErrors({
            [FormError.DATE_MUST_BE_GREATER_THAN]: {
              invalid: true,
              field: opt.label,
              compareField: compareOpt.label,
            },
          });
        } else {
          removeFieldError(
            validateControl,
            FormError.DATE_MUST_BE_GREATER_THAN,
          );
        }
        break;
      case FormDateCompareEnum.BEFORE_OR_EQUAL:
        if (
          new Date(validateControl.value) >=
          getDateValueForCompare(compareControl.value, compareOpt.type)!
        ) {
          validateControl.setErrors({
            [FormError.DATE_MUST_BE_GREATER_THAN]: {
              invalid: true,
              field: opt.label,
              compareField: compareOpt.label,
            },
          });
        } else {
          removeFieldError(
            validateControl,
            FormError.DATE_MUST_BE_GREATER_THAN,
          );
        }
        break;
      default:
        break;
    }

    return null;
  };
}

function compareNumberValidator(
  opt: FormResponseDto,
  compareOpt: FormResponseDto,
  compareKey?: string,
  type?: string,
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const validateControl = control.get(opt.key);
    const compareControl = control.get(compareKey!);

    if (!validateControl || !compareControl) {
      return null;
    }

    const validateValue = +validateControl.value;
    const compareValue = +compareControl.value;

    switch (type) {
      case FormNumberCompareEnum.SMALLER_THAN_OR_EQUAL:
        if (validateValue > compareValue) {
          validateControl.setErrors({
            [FormError.NUMBER_MUST_BE_SMALLER_THAN_OR_EQUAL]: {
              invalid: true,
              field: opt.label,
              compareField: compareOpt.label,
            },
          });
        } else {
          removeFieldError(
            validateControl,
            FormError.NUMBER_MUST_BE_SMALLER_THAN_OR_EQUAL,
          );
        }
        break;
      case FormNumberCompareEnum.BIGGER_THAN_OR_EQUAL:
        if (validateValue < compareValue) {
          validateControl.setErrors({
            [FormError.NUMBER_MUST_BE_BIGGER_THAN_OR_EQUAL]: {
              invalid: true,
              field: opt.label,
              compareField: compareOpt.label,
            },
          });
        } else {
          removeFieldError(
            validateControl,
            FormError.NUMBER_MUST_BE_BIGGER_THAN_OR_EQUAL,
          );
        }
        break;
      default:
        break;
    }

    return null;
  };
}

function compareNumberSumValidator(
  opt: FormResponseDto,
  compareOpts: FormResponseDto[],
  compareKeys?: string[],
  type?: string,
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const validateControl = control.get(opt.key);

    if (!validateControl) {
      return null;
    }

    const validateValue = +validateControl.value;

    let sum = 0;
    for (const key of compareKeys!) {
      const compareControl = control.get(key!);
      if (!compareControl) {
        continue;
      }

      sum += +compareControl.value;
    }

    switch (type) {
      case FormNumberCompareEnum.SMALLER_THAN_OR_EQUAL:
        if (validateValue > sum) {
          validateControl.setErrors({
            [FormError.NUMBER_MUST_BE_SMALLER_THAN_OR_EQUAL_SUM]: {
              invalid: true,
              field: opt.label,
              compareField: compareOpts.map((item) => item.label),
            },
          });
        } else {
          removeFieldError(
            validateControl,
            FormError.NUMBER_MUST_BE_SMALLER_THAN_OR_EQUAL_SUM,
          );
        }
        break;
      case FormNumberCompareEnum.BIGGER_THAN_OR_EQUAL:
        if (validateValue < sum) {
          validateControl.setErrors({
            [FormError.NUMBER_MUST_BE_BIGGER_THAN_OR_EQUAL_SUM]: {
              invalid: true,
              field: opt.label,
              compareField: compareOpts.map((item) => item.label),
            },
          });
        } else {
          removeFieldError(
            validateControl,
            FormError.NUMBER_MUST_BE_BIGGER_THAN_OR_EQUAL_SUM,
          );
        }
        break;
      default:
        break;
    }

    return null;
  };
}

function getDateValueForTranslation(date: Date, type: FormFieldType) {
  let dateStr;
  switch (type) {
    case FormFieldType.YEAR:
      dateStr = new Date(date).getFullYear();
      break;
    case FormFieldType.MONTH:
      dateStr = new Date(date).getMonth() + '.' + new Date(date).getFullYear();
      break;
    case FormFieldType.DATE:
      dateStr =
        new Date(date).getDate() +
        '.' +
        new Date(date).getMonth() +
        '.' +
        new Date(date).getFullYear();
      break;
  }

  return dateStr;
}

function getDateValueForCompare(date: Date, type: FormFieldType) {
  let compareDate;
  switch (type) {
    case FormFieldType.YEAR:
      compareDate = new Date(new Date(date).getFullYear(), 0, 1, 0, 0, 0, 0);
      break;
    case FormFieldType.MONTH:
      compareDate = new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        1,
        0,
        0,
        0,
        0,
      );
      break;
    case FormFieldType.DATE:
      compareDate = date;
      break;
  }

  return compareDate;
}

function minDateValidator(opt: FormResponseDto, minDate: Date) {
  return (control: any) => {
    const selectedDate = control.value;
    const date = getDateValueForTranslation(minDate, opt.type);
    return selectedDate >= new Date(minDate)
      ? null
      : {
          [FormError.MIN_DATE]: { date: date },
        };
  };
}

function maxDateValidator(opt: FormResponseDto, maxDate: Date) {
  return (control: any) => {
    const selectedDate = control.value;
    const date = getDateValueForTranslation(maxDate, opt.type);
    return selectedDate <= new Date(maxDate)
      ? null
      : { [FormError.MAX_DATE]: { date: date } };
  };
}

function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    const password = control.value;
    const valid = PASSWORD_REGEXP.test(password);

    return valid ? null : { [FormError.INVALID_PASSWORD]: true };
  };
}

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    const email = control.value;
    return isEmail(email) ? null : { [FormError.INVALID_EMAIL]: true };
  };
}

export function integerValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    if (control.value == null || control.value === '') {
      return null;
    }

    const valid = Number.isInteger(+control.value);
    return valid ? null : { [FormError.INTEGER]: { value: control.value } };
  };
}

export function urlValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    if (!control.value) {
      return null;
    }

    const urlPattern =
      /^https?:\/\/[\w.-]+(?:\.[\w.-]+)+(?:\/[\w.-]*)*(?:\?[^\s]*)?$/i;

    return urlPattern.test(control.value)
      ? null
      : { [FormError.INVALID_URL]: true };
  };
}

export const CustomValidators = {
  integerValidator,
  passwordMatchValidator,
  compareDateValidator,
  compareNumberValidator,
  compareNumberSumValidator,
  minDateValidator,
  maxDateValidator,
  urlValidator,
  password: () => passwordValidator(),
};
